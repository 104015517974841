
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --radius: 0.5rem;
  }
  *:not(input):not(textarea):focus-visible {
    outline: none;
    box-shadow:
      0 0 0 1px var(--color-opacity-violet-424, rgba(103, 88, 255, 0.24)),
      0 0 0 4px var(--color-opacity-violet-28, rgba(103, 88, 255, 0.08));
  }
}

body {
  margin: 0;
  font-family:
    Pretendard Variable,
    sans-serif;
  line-height: 140%;
  white-space: pre-wrap;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  --keyboard-zindex: 3000;

  @apply text-default;
}

[lang='ja'] {
  body {
    font-family: 'Pretendard JP Variable', sans-serif;
  }
}

a {
  color: inherit; /* 링크 색상을 부모 요소의 색상으로 설정 */
  text-decoration: none; /* 밑줄 제거 */
  background-color: transparent; /* 배경색 투명 */
  -webkit-text-decoration-skip: objects; /* Safari 브라우저에서 밑줄 스타일 조정 */
}

a:hover,
a:active {
  text-decoration: none;
}

strong {
  font-weight: inherit;
}

* {
  scrollbar-color: rgb(0 0 0 / 20%) transparent;
  scrollbar-width: thin;
}

// scrollbar styles (chrome)
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(#bec3cc, 0.5);
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 9999px;

  &:hover {
    background-color: #bec3cc;
  }
}

.placeholder-center::placeholder {
  text-align: center;
}

input {
  line-height: 130%;
  outline: none;
}

input::placeholder {
  color: #bec3cc;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888; /* 핸들의 배경색을 설정합니다 */
  border-radius: 10px; /* 핸들의 모서리를 둥글게 설정합니다 */

  &:hover {
    background: #555; /* 호버 시 핸들의 배경색을 변경합니다 */
  }
}

.nav-link a {
  @apply cursor-pointer text-[#0D94FF] underline decoration-[#0D94FF] hover:underline;
}

.word-break-all * {
  word-break: keep-all;
  word-wrap: break-word;
}

math-field {
  padding: 0px 12px;
  // line-height: 1.3;
  border-radius: 6px;
  border: 1px solid #e9eaf2;
}

math-field:focus-within {
  outline: 1px solid #6758ff;
}

math-field::part(menu-toggle) {
  display: none;
}

math-field::part(virtual-keyboard-toggle) {
  display: none;
}

math-field::part(container),
math-field::part(content) {
  width: 100%;
  height: 100%;
  display: block;
}

math-field::part(placeholder) {
  overflow: hidden;
  color: var(--Input-label-placeholder, #cacbd8);
  text-overflow: ellipsis;

  /* heading/sm-bold */
  // font-family: var(--typography-font-family-pretendard, 'Pretendard JP');
  // font-size: var(--text-heading-sm-font-size, 18px);
  // font-style: normal;
  // font-weight: var(--text-heading-sm-font-weight, 700);
  // line-height: var(--text-heading-sm-line-height, 25.2px); /* 140% */
}


      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
.icon_container {
  --slop: 8;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  .slop {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + var(--slop) / 16 * 1rem);
    height: calc(100% + var(--slop) / 16 * 1rem);
    transform: translate(-50%, -50%);

    @at-root .visible_hit_slop .slop {
      background-color: rgba(#27262e, 20%);
      border: 1px dotted #27262e;
    }
  }
}
